/** @jsx jsx */
import { jsx, ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import { useState, useEffect } from 'react'

import mq from '../styles/mediaQueries'

import {
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  Headline6,
  Subheading1,
  Subheading2,
  Body1,
  Body2,
} from './Typography'

const dayTheme = {
  headerColor: 'var(--terminal-500)',
  bodyColor: 'var(--terminal-400)',
  linkedTextColor: 'var(--screen-500)',
  captionColor: 'var(--terminal-300)',
  borderColor: 'var(--terminal-100)',
}

const nightTheme = {
  headerColor: 'var(--terminal-0)',
  bodyColor: 'var(--terminal-50)',
  linkedTextColor: 'var(--screen-300)',
  captionColor: 'var(--terminal-100)',
  borderColor: 'var(--terminal-300)',
}

const ContentStyles = styled.div`
  ${mq({
    marginBottom: ['160px', '160px', '160px', '200px', '200px'],
  })};

  blockquote {
    position: relative;
  }

  blockquote p::before {
    content: '“';
    position: absolute;
    top: 0.33rem;
    left: 0;
    margin-right: 0.5rem;
    color: var(--terminal-400);
    font-family: var(--mono-font-stack);
    font-size: 2.5rem;
  }

  blockquote p {
    color: var(--firewall-100);
    font-size: 1.5rem;
    padding-left: 2rem;
  }

  h1 {
    ${Headline1};
    margin-block-start: 0;
    margin-block-end: 0;
    color: ${props => props.theme.headerColor};
    pointer-events: none;
    ${mq({
      marginBottom: ['48px', '48px', '48px', '60px', '60px'],
    })};
  }

  h2 {
    ${props => (props.smallerHeaders ? Headline3 : Headline2)};
    color: ${props => props.theme.headerColor};
    margin-block-start: 0;
    margin-block-end: 0;
    margin: ${props =>
      props.smallerHeaders ? '60px 0 16px 0' : '80px 0 20px 0'};
  }

  h3 {
    ${props => (props.smallerHeaders ? Headline4 : Headline3)};
    color: ${props => props.theme.headerColor};
    margin-block-start: 0;
    margin-block-end: 0;
    margin: ${props =>
      props.smallerHeaders ? '40px 0 12px 0' : '60px 0 16px 0'};
  }

  h4 {
    ${props => (props.smallerHeaders ? Headline5 : Headline4)};
    color: ${props => props.theme.headerColor};
    margin-block-start: 0;
    margin-block-end: 0;
    margin: ${props =>
      props.smallerHeaders ? '30px 0 0px 0' : '40px 0 12px 0'};
  }

  h5 {
    ${props => (props.smallerHeaders ? Headline6 : Headline5)};
    color: ${props => props.theme.headerColor};
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: ${props => (props.smallerHeaders ? '20px' : 'unset')};
  }

  h6 {
    ${Headline6};
    color: ${props => props.theme.headerColor};
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 20px;
  }

  h1 code,
  h2 code,
  h3 code,
  h4 code,
  h5 code,
  h6 code {
    font-size: inherit;
    padding: initial;
    background: initial;
    color: inherit;
    font-size: 0.9em;
    line-height: 1;
  }

  .subheading {
    ${Subheading2};
    ${mq({
      margin: ['48px 0', '48px 0', '48px 0', '60px 0', '60px 0'],
    })};
    color: ${props => props.theme.bodyColor};
  }

  p {
    ${Body1};
    margin-top: 20px;
    color: ${props => props.theme.bodyColor};
  }

  small {
    ${Body2};
    margin-top: 20px;
    color: ${props => props.theme.bodyColor};
  }

  table {
    ${Body1};
    color: ${props => props.theme.bodyColor};
    font-size: 16px;
  }

  td,
  th {
    padding-right: 5px;
    text-align: left;
  }

  .caption {
    ${Body2};
    color: ${props => props.theme.captionColor};
  }

  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 25px;
    margin: 16px 0 20px 0;

    list-style-type: '⬝   ';
  }

  ul.contains-task-list {
    list-style-type: none;
  }

  ol {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 25px;
    margin: 16px 0 20px 0;
  }

  li {
    ${Body1};
    color: ${props => props.theme.bodyColor};
  }

  hr {
    width: 100%;
    border-top: 1px solid;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-color: ${props => props.theme.borderColor};
    padding-inline-start: 0;
    margin: 60px 0;
  }

  blockquote {
    ${Subheading1};
    color: ${props => props.theme.bodyColor};
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  a {
    color: ${props => props.theme.linkedTextColor};
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }
`

function Content(props) {
  const [theme, setTheme] = useState(dayTheme)

  useEffect(() => {
    if (props.theme === 'day') {
      setTheme(dayTheme)
    }
    if (props.theme === 'night') {
      setTheme(nightTheme)
    }
  }, [props.theme])

  return (
    <ThemeProvider theme={theme}>
      <ContentStyles smallerHeaders={props.smallerHeaders}>
        {props.children}
      </ContentStyles>
    </ThemeProvider>
  )
}

export default Content
